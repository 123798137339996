'use strict';
(function () {
angular.module('xp-element-multi-part', ['angularWidget', 'client.services', 'client.directives', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
  .controller('clientMultiPartElementCtrl',
    ['$scope', '$log','widgetConfig', '$http', 'ElementsRestService', 'ElementUtilities',
    'JSONStringUtility', '$timeout', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService',
    'IconOverlays', 'RespondentType', '$sce', 'ModalService',  '$q', 'ActiveExperience',
    function ($scope, $log, widgetConfig, $http, ElementsRestService, ElementUtilities,
      JSONStringUtility, $timeout, SHARE_MODE, GATE_MODE, ElementsErrorService,
      IconOverlays, RespondentType, $sce, ModalService, $q, ActiveExperience) {

      $scope.options = widgetConfig.getOptions($scope);
      $scope.DISPLAY_MODE = Object.freeze({kTeacherMode : 0, kStudentModeRead : 1, kStudentModeWrite : 2});
      $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
      $scope.elementInitialized = false;
      $scope.instructions = {};
      $scope.questions = [];
      $scope.isTeacher = false;
      $scope.selections = [];

      function parseElement() {
        var element = $scope.options.element;
        var questions = [];

        element.config.attributes.forEach(function(attribute) {
          var name = attribute.name;
          var value = attribute.value;
          switch (name)
          {
            case "text" :
              $scope.instructions.question = $sce.trustAsHtml(attribute.value);
            break;
            case "question_image_url" :
              $scope.instructions.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
              break;
            case "assessed" :
              $scope.isAssessed = attribute.value === "true" || attribute.value === true;
            break;
            case "share" :
              $scope.share = attribute.value;
            break;
            case "style" :
              if (attribute.value == "default") {
                $scope.instructions.border = "image-element-border";
              }
            break;
            case "alignment" :
              if (attribute.value == "left") {
                $scope.instructions.alignment = "image-element-alignment-left";
              } else if (attribute.value == "right") {
                $scope.instructions.alignment = "image-element-alignment-right";
              }
            break;
            case "questions" :
              questions = attribute.value;
              if (!(questions instanceof Array)) {
                questions =
                  [
                   {
                      name: "question",
                      value: questions.question
                   }
                  ];
              }
            break;
          }
        });

        questions.forEach(function(question, index){
          var mcQuestion = {
            index: index,
            text : question.value.text,
            enumerator: question.value.enumerator,
            imageUrl: question.value.question_image_url,
            multiple_allowed: question.value.multiple_allowed,
            maxSelect: question.value.max_selections ? parseInt(question.value.max_selections, 10): question.value.options.length,
            options: [],
            correctChoices: 0,
            hasCorrectAnswers: false,
            tabularData: []
          };
          var optionIndex = 0;
          question.value.options.forEach(function(option){
            mcQuestion.options.push(
              {
                text : $sce.trustAsHtml(option.text),
                id : optionIndex,
                isCorrect : option.correct && (option.correct === "true" || option.correct === true),
                code : getEnumerator(mcQuestion, optionIndex),
                numberOfAnswersForOption : 0,
                respondentIds : {},
                respondents : [],
                displayRespondents : false
              });
            optionIndex = optionIndex + 1;

            // Keep indicator to determine if any options are marked as correct
            mcQuestion.hasCorrectAnswers = mcQuestion.hasCorrectAnswers ||
              (option.correct && (option.correct === "true" || option.correct === true));

            // Keep track of the number of correct answers for multiple select type choice
            if (option.correct && (option.correct === "true" || option.correct === true)) {
              mcQuestion.correctChoices = mcQuestion.correctChoices + 1;
            }
          });
          $scope.questions.push(mcQuestion);
          $scope.selections.push([]);
        });
      }

      function getEnumerator(question, index) {
        switch (question.enumerator)
        {
          case 'number':
            return (index + 1) + ")";
          case 'bullet':
            return String.fromCharCode(8226);
          case 'none':
            return "";
        }
        // implies letter or unset
        return String.fromCharCode(65 + index) + ")";
      }

      $scope.$watch('options', function(newValue, oldValue, scope) {
        var options = $scope.options;
        if (!options.element)
          return;

        // if already initialized then return
        if ($scope.elementInitialized) {
          return;
        }

        var service = options.elementRealtimeService;
        var EVENTS = service.EVENTS;
        var context = options.context;
        parseElement();

        $scope.isTeacher = context.userIsTeacher();

        if (context.userIsTeacher() || $scope.isUsingSmallGroups()) {
          service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
          $scope.$on('$destroy', function(){
            service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
          });
        }

        $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentModeRead;

        $scope.selectedRespondent = $scope.isUsingSmallGroups() ? context.groupId : context.userId;

        loadAnswers(true);

        // mark this element as initialized
        $scope.elementInitialized = true;
      }, true);

      function loadAnswers(firstTime) {
        if (!$scope.options.element.id)
          return;

        var isInactive = $scope.options.context.getViewingInactiveExperience();

        ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
          function(result) {
            processSharedState(result, firstTime);
          },
          function(error) {
            ElementsErrorService.error(error);
        });
      }

      $scope.$watch('savingElementId', function(newValue, oldValue) {
        // Submit the response selected in this element
        if (newValue && newValue.elementId === $scope.options.element.id){
          $scope.didSubmit(newValue);
        }
      }, true);

      function getStudentName(user_id) {
        var name = user_id;

        // Search for the matching student name in the clazz
        $scope.options.context.clazz.students.forEach(function(student){
          if (student.uid === user_id)
            name = student.displayName;
        });

        return name;
      }

      function processSharedState(result, firstTime) {
        var context = $scope.options.context;

        $scope.questions.forEach(function(question) {
          question.tabularData = [];
          question.totalResponses = 0;
          question.options.forEach(function(option){
            option.numberOfAnswersForOption = 0;
            option.respondentIds = {};
            option.respondents = [];
            option.displayRespondents = false;
            option.percentage = 0;
          });
        });

        if (result instanceof Array && result.length) {
          $scope.filterAnswers(result).forEach(function(answer){
            answer.user_data = JSONStringUtility.parse(answer.user_data);
            if (!$scope.options.studentId || $scope.options.studentId === answer.user_id) {
              if (answer.user_data && answer.user_data instanceof Array) {
                $scope.questions.forEach(function(question, qIndex) {
                  if (qIndex < answer.user_data.length) {
                    var responses = answer.user_data[qIndex];
                    if (responses) {
                      responses.forEach(function(response, answerIndex) {
                        if ($scope.isUsingSmallGroups()) {
                          if (!question.options[response].respondentIds[answer.small_gid]) {
                            question.options[response].numberOfAnswersForOption = question.options[response].numberOfAnswersForOption + 1;
                            question.totalResponses = question.totalResponses + 1;
                          }
                        } else {
                          question.options[response].numberOfAnswersForOption = question.options[response].numberOfAnswersForOption + 1;
                          if (answerIndex === 0) {
                            question.totalResponses = question.totalResponses + 1;
                          }
                        }
                        question.options[response].respondentIds[$scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id] = true;

                        // See if this student already has a row
                        var found = false;
                        question.tabularData.forEach(function(tabrow){
                          if (tabrow.uid == answer.user_id) {
                            tabrow.Response += ", " + (question.options[response].code ? question.options[response].code : "") + " " + (question.options[response].text ? question.options[response].text : "");
                            if (question.options[response].isCorrect && question.correctChoices) {
                              tabrow.score = tabrow.score + (100 / question.correctChoices);
                            }
                            found = true;
                          }
                          else if (answer.small_gid && tabrow.gid == answer.small_gid) {
                            found = true;
                          }
                        });

                        // Store values for tabular display
                        if (!found) {
                          var tabularRow = {};
                          tabularRow.Student = getStudentName(answer.user_id);
                          tabularRow.gid = answer.small_gid ? answer.small_gid : null;
                          tabularRow.uid = answer.user_id;
                          tabularRow.score = question.options[response].isCorrect && question.correctChoices ? (100 / question.correctChoices) : 0;
                          tabularRow.isScored = question.hasCorrectAnswers;
                          tabularRow.Response = (question.options[response].code ? question.options[response].code : "") + " " + (question.options[response].text ? question.options[response].text : "");
                          question.tabularData.push(tabularRow);
                        }
                      });
                    }
                  }
                });
              }
            }

            if (answer.user_data && answer.user_data.length &&
                (($scope.isUsingSmallGroups() && answer.small_gid == context.groupId) || (!$scope.isUsingSmallGroups() && answer.user_id == context.userId))) {
              $scope.selections = answer.user_data;
            }
          });

          if (context.userIsTeacher()) {
            $scope.questions.forEach(function(question) {
              question.options.forEach(function(option){
                option.percentage = Math.round((option.numberOfAnswersForOption * 100) / question.totalResponses);
              });
              if ($scope.isUsingSmallGroups()) {
                $scope.portionResponded = (question.totalResponses / $scope.questions.length) / context.clazz.smallGroups;
              }
              else if (context.clazz.students.length) {
                $scope.portionResponded = (question.totalResponses / $scope.questions.length) / context.clazz.students.length;
              }
              else {
                $scope.portionResponded = 0;
              }
            });
          }
        }

        var cachedResponse = false;

        if ($scope.cached) {
          var cachedValue = $scope.cached({elementId: $scope.options.element.id});
          if (cachedValue) {
            $scope.selections = cachedValue;
            $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
            cachedResponse = true;
          }
        }

        if (context.userIsTeacher()) {
          $scope.questions.forEach(function(question) {
            question.options.forEach(function(option){
              if ($scope.isUsingSmallGroups()) {
                for (var i = 1; i <= context.clazz.smallGroups; ++i) {
                  if (option.respondentIds.hasOwnProperty(i)) {
                    option.respondents.push(i);
                  }
                }
                option.respondents.sort(function(a, b) {return a - b;});
              }
              else {
                context.clazz.teachers.forEach(function(teacher){
                  if (option.respondentIds.hasOwnProperty(teacher.uid)) {
                    option.respondents.push(teacher);
                  }
                });
                context.clazz.students.forEach(function(student){
                  if (option.respondentIds.hasOwnProperty(student.uid)) {
                    option.respondents.push(student);
                  }
                });
                option.respondents.sort(function(a, b) {return a.displayName.localeCompare(b);});
              }
              option.displayRespondents = option.respondents.length > 0;
            });
          });
        }

        // If the student has already answered (and this is not a quiz) then they must pick the edit menu to change their response
        $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
        if (context.userIsStudent() && (($scope.selections.length && !$scope.selections[0].length) || $scope.options.quiz || cachedResponse) && !$scope.options.context.getViewingInactiveExperience()) {
          $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
        }

        if (firstTime) {
          // Notify the widget that were are done loading the data
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
        }
      }

      function stateChangedNotificationHandler(e) {
        var state = e.detail;
        if (state.record.element_id != $scope.options.element.id) return;
        if (state.record.user_id == $scope.options.context.userId) return;

        $log.debug ("Received multi-part state update: " + JSON.stringify(state));

        loadAnswers(false);
      }

      $scope.canEditForRespondent = function(respondentId) {
        if (!$scope.options.context)
          return false;

        if ($scope.options.context.isPreview) {
          return false;
        }

        return ($scope.isTeacher ||
                (!$scope.isTeacher && $scope.displayMode == $scope.DISPLAY_MODE.kStudentModeRead && allAnswered())) &&
                !$scope.options.context.getViewingInactiveExperience() &&
                !$scope.options.quiz;
      };

      $scope.getEditMenuItemsForUser = function(respondentId) {
        var menuOptions =
        [
          {
            text: '<div class="xp-element-menu-edit">Edit</div>',
            click: 'requestEdit("' + respondentId +'")'
          }
        ];

        if ($scope.displayMode == $scope.DISPLAY_MODE.kStudentModeWrite) {
          menuOptions =
          [
            {
              text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
              click: 'cancelEdit("' + respondentId +'")'
            }
          ];
        }

        return menuOptions;
      };

      $scope.oldResponse = null;
      $scope.requestEdit = function(respondentId) {
        // reset the assessment status flag to hide the alert if it is showing
        $scope.assessmentStatus = false;

        // Save the current responses in case the user cancels
        $scope.oldResponse = $scope.selections.concat();

        // Set mode so input is enabled
        $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeWrite;
      };

      $scope.cancelEdit = function(respondentId) {
        $scope.selections = $scope.oldResponse.concat();
        $scope.displayMode = $scope.DISPLAY_MODE.kStudentModeRead;
      };

      $scope.showCorrectIndicator = function(questionIndex, option) {
        if (!option.isCorrect) {
          return false;
        }
        else if ($scope.isTeacher || (displayStudentFacingFeedback() && studentSelectedOption(questionIndex, option))) {
         return true;
        }
      };

      $scope.showInCorrectIndicator = function(questionIndex, option) {
        if (!$scope.isTeacher && displayStudentFacingFeedback() && $scope.displayMode == $scope.DISPLAY_MODE.kStudentModeRead &&
            !option.isCorrect && studentSelectedOption(questionIndex, option)) {
          return true;
        }
        else {
          return false;
        }
      };

      $scope.shouldShowResults = function () {
        if (!$scope.options || !$scope.options.context) {
          return false;
        }

        return $scope.options.context.userIsTeacher();
      };

      $scope.numberOfRespondentsWhoSubmitted = function() {
        if (!$scope.questions || !$scope.questions.length) {
          return 0;
        }

        return $scope.questions[0].totalResponses;
      };

      $scope.progressDisplayTextForChoiceView = function()
      {
          if (!$scope.options || !$scope.shouldShowResults())
              return null;

          var totalCount = $scope.isUsingSmallGroups() ? $scope.options.context.clazz.smallGroups : $scope.options.context.clazz.students.length;
          var submittedCount = $scope.numberOfRespondentsWhoSubmitted();
          if (submittedCount === 0)
              return '' + totalCount + ' yet to respond';
          if (totalCount > submittedCount) {
              var unsubmittedCount = totalCount - submittedCount;
              return unsubmittedCount + ' yet to respond';
          }
          else {
              return 'All ' + submittedCount + ' ' + ($scope.isUsingSmallGroups() ? 'groups' : 'students') + ' have responded';
          }
      };

      $scope.isTeacherViewingInactiveExperience = function() {
        if ($scope.options.context)
          return $scope.options.context.getViewingInactiveExperience() &&
              $scope.options.context.userIsTeacher() &&
              $scope.questions[0].tabularData.length > 0 &&
              !$scope.filteredStudent &&
              !$scope.options.studentId;
        else
          return false;
      };

      $scope.isUsingSmallGroups = function() {
        return SHARE_MODE.isUsingSmallGroups($scope.share);
      };

      $scope.respondentIsUser = function(respondentId) {
        return !$scope.isUsingSmallGroups() || respondentId === 0;
      };

      $scope.respondentIsGroup = function(respondentId) {
        return !$scope.respondentIsUser(respondentId);
      };

      $scope.showAnswers = function() {
        return $scope.isTeacher &&
                ((ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideStudentResponses) &&
                    $scope.displayMode != $scope.DISPLAY_MODE.kStudentModeWrite);
      };

      function allAnswered() {
        var allAnswered = true;
        $scope.selections.forEach(function(selection) {
          if (!selection.length) {
            allAnswered = false;
          }
        });
        return allAnswered;
      }

      $scope.shouldShowSubmit = function() {
        if ($scope.displayMode == $scope.DISPLAY_MODE.kStudentModeWrite && !$scope.options.context.getViewingInactiveExperience() && !$scope.options.quiz && $scope.selections) {
          return allAnswered();
        }
        return false;
      };

      $scope.toggleRadio = function(questionIndex, id) {
        $scope.selections[questionIndex] = [id];
        if ($scope.selectionMade) {
          $scope.selectionMade({selected: true});
        }
        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: $scope.selections});
        }
      };

      $scope.toggleCheck = function(questionIndex, id, event) {
        if (!$scope.questions[questionIndex].multiple_allowed) {
          $scope.selections[questionIndex] = [id];
        } else {
          if ($scope.selections[questionIndex].includes(id)) {
            $scope.selections[questionIndex] = $scope.selections[questionIndex].filter(function(response) { return response !== id; });
          } else {
            if (!$scope.questions[questionIndex].maxSelect || $scope.selections[questionIndex].length < $scope.questions[questionIndex].maxSelect) {
              $scope.selections[questionIndex].push(id);
            }
            else {
              event.preventDefault();
            }
          }
        }

        if ($scope.changed) {
          $scope.changed({elementId: $scope.options.element.id, selection: $scope.selections});
        }

        // Let the quiz know that at least one response is input
        if ($scope.selectionMade) {
          $scope.selectionMade({selected: $scope.selections[questionIndex].length > 0 ? true : false});
        }
      };

      function checkResponses(selections) {
        var allCorrect = true;
        var someCorrect = false;

        // Iterate over the responses and see if each correctly matches the correct response
        $scope.questions.forEach(function(question, qIndex) {
          question.options.forEach(function(option, index) {
            // See if this matches the nth value in the source
            if ((option.isCorrect && selections[qIndex].indexOf(option.id) === -1) ||
                (!option.isCorrect && selections[qIndex].indexOf(option.id) !== -1)) {
              allCorrect = false;
            }
            else if (option.isCorrect && selections[qIndex].indexOf(option.id) !== -1) {
              someCorrect = true;
            }
          });
        });

        return allCorrect ? 1 : (someCorrect ? -1 : 0);
      }

      function hasAnyCorrectResponses() {
        var hasCorrect = false;
        $scope.questions.forEach(function(question) {
          question.options.forEach(function(option) {
            hasCorrect = hasCorrect || option.isCorrect;
          })
        });
        return hasCorrect;
      }

      $scope.didSubmit = function(savingElement) {
        let groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());

        // show the correct alert if student facing feedback is enabled
        if ($scope.options.context.getStudentFacingFeedback() && hasAnyCorrectResponses() && !$scope.options.quiz) {
          $scope.assessmentStatus = checkResponses($scope.selections);
        }

        // Save the new userstate information to the server
        ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.userId, groupId,
                                          $scope.selections, function() {
          // Calls back to quiz letting it know the score has been saved
          if ($scope.scoreSaved) {
            $scope.scoreSaved({ finished: (savingElement && savingElement.finished)});
          }
          if ($scope.changed) {
            $scope.changed({elementId: $scope.options.element.id, selection: null});
          }
          loadAnswers(false);
        },
        function(error) {
          ElementsErrorService.error(error);
        });
      };

      $scope.onRetry = function() {
        $scope.requestEdit();
      };

      function displayStudentFacingFeedback() {
        // if this is a student they are not editing and they are viewing their own data
        return (($scope.options.context.getStudentFacingFeedback() || $scope.options.context.getViewingInactiveExperience()) &&
                !$scope.isTeacher &&
                $scope.displayMode == $scope.DISPLAY_MODE.kStudentModeRead &&
                allAnswered());
      }

      function studentSelectedOption(questionIndex, option) {
        return $scope.selectons &&
                $scope.selectons[questionIndex] &&
                $scope.selectons[questionIndex].indexOf(option.id) != -1;
      }

      $scope.showCorrectAnswer = function(questionIndex, option) {
        if (!option.isCorrect) {
          return false;
        }
        else if ($scope.options.context.getViewingInactiveExperience() && ($scope.isTeacher ||
            (displayStudentFacingFeedback() && !studentSelectedOption(questionIndex, option)))) {
         return true;
        }
      };

    }]);
})();
