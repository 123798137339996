'use strict';

angular.module('client.services').factory('UserConfig', ['$resource', 'xpAlert', 'userPermissions', 'api_server',
  function ($resource, xpAlert, userPermissions, api_server) {
    var url = api_server + '/users/:userId/config';
    return $resource(url, {userId: '@userId'}, {
      get: {url: url, method: 'GET', params: {userId: '@userId'}, isArray: true, cache: false},
      save: {url: url, method: 'PUT', params: {userId: '@userId'}, isArray: true, cache: false},
      delete: {url: url, method: 'DELETE', params: {userId: '@userId'}, isArray: true, cache: false}
    });
  }]);

