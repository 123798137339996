'use strict';
(function () {
  angular.module('xp-element-inline-choice', ['angularWidget', 'client.services', 'client.directives', 'ngAnimate', 'mgcrea.ngStrap', 'ngSanitize'])
    .controller('clientInlineChoiceElementCtrl',
      ['$scope', '$rootScope', '$log', 'widgetConfig', '$http', 'ElementsRestService', 'ElementUtilities',
        'JSONStringUtility', '$timeout', 'SHARE_MODE', 'GATE_MODE', 'ElementsErrorService',
        'IconOverlays', 'RespondentType', '$sce', 'ModalService', '$q', 'ActiveExperience', 'CorrectResponseId',
        function ($scope, $rootScope, $log, widgetConfig, $http, ElementsRestService, ElementUtilities,
                  JSONStringUtility, $timeout, SHARE_MODE, GATE_MODE, ElementsErrorService,
                  IconOverlays, RespondentType, $sce, ModalService, $q, ActiveExperience, CorrectResponseId) {

          let QUESTION_PARSE_MODE = Object.freeze({QUESTION: 'question', ANSWER: 'answer'});

          $scope.instructions = {};
          $scope.questions = [];
          $scope.correctChoices = [];
          $scope.editing = false;
          $scope.respondents = [];
          $scope.selectedRespondent = null;
          $scope.isTeacher = false;
          $scope.responses = [];
          $scope.status = [];
          $scope.answered = false;
          $scope.answerCount = 0;
          $scope.allResponses = [];
          $scope.isDirty = false;
          $scope.allowEditing = false;
          $scope.isAssessed = false;
          $scope.initialized = false;
          $scope.groupUser = {};

          let context = null;
          let element = null;
          let share = null;

          $scope.gateMode = GATE_MODE.XPGateModeGated;
          $scope.SHARE_MODE = SHARE_MODE;
          $scope.share = SHARE_MODE.TEACHER;

          // The element is the block of data provided by the source xml
          $scope.options = widgetConfig.getOptions($scope);

          let parseElement = function () {
            if (!$scope.options.element || !$scope.options.element.config || !$scope.options.element.config.attributes) {
              return;
            }

            $scope.options.element.config.attributes.forEach(function (attribute) {
              switch (attribute.name) {
                case "text" :
                  $scope.instructions.question = $sce.trustAsHtml(attribute.value);
                  break;
                case "image_url" :
                  $scope.instructions.imageUrl = ElementUtilities.getElementURL($scope.options.element, $scope.options.context.experienceId, attribute.value);
                  break;
                case "assessed" :
                  $scope.isAssessed = attribute.value === "true" || attribute.value === true;
                  break;
                case "share" :
                  $scope.share = attribute.value;
                  break;
                case "style" :
                  if (attribute.value == "default") {
                    $scope.instructions.border = "image-element-border";
                  }
                  break;
                case "alignment" :
                  if (attribute.value == "left") {
                    $scope.instructions.alignment = "image-element-alignment-left";
                  } else if (attribute.value == "right") {
                    $scope.instructions.alignment = "image-element-alignment-right";
                  }
                  break;
                case "questions" : {
                  let questions = attribute.value;
                  if (!(questions instanceof Array)) {
                    questions =
                      [
                        {
                          name: "question",
                          value: questions.question
                        }
                      ];
                  }
                  let answerCount = 0;
                  questions.forEach(function (question) {
                    question = parseQuestion(question.value.text, answerCount);
                    $scope.questions.push(question);

                    // Save for choice list
                    question.answers.forEach(function (answer) {
                      let correctChoice = answer.choices.find((function (choice) {
                        return choice.correct;
                      }));
                      if (correctChoice) {
                        $scope.correctChoices.push(correctChoice.text);
                      }
                    });

                    // Increase answer count by # of answers in this question
                    answerCount += question.locations;
                  });
                  $scope.answerCount = answerCount;
                }
                  break;
              }
            });

            // Allocate enough space in the arrays for all responses
            $scope.responses = createBlankArray($scope.answerCount);

            $timeout(function () {
              // Notify the widget that were are done loading the data
              widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
            });
          };

          function createBlankArray(size) {
            let result = [];
            for (let index = 0; index < size; ++index) {
              result.push(null);
            }
            return result;
          }

          function parseQuestion(question, startIndex) {
            let result = {segments: [], answers: [], locations: 0};

            // Search through question for each option marker
            let source = question;

            let count = 0;
            let mode = QUESTION_PARSE_MODE.QUESTION;
            let index = source.indexOf("___");
            while (index >= 0) {
              // if this is the question then left of text is part of the question
              if (mode === QUESTION_PARSE_MODE.QUESTION) {
                result.segments.push({text: $sce.trustAsHtml(source.slice(0, index)), position: startIndex + count});
                count = count + 1;
              } else {
                // Grab the portion of the text that is the answer
                let inlineAnswer = source.slice(0, index);

                // Split this into an array
                let inlineChoices = [];
                let choices = inlineAnswer.split(',');
                if (choices && choices.length) {
                  inlineChoices = choices.map(function (choice) {
                    choice = choice.trim();
                    let inlineChoice = {text: choice, correct: false};
                    // A star at the end of the text indicates the correct answer
                    if (choice.length && choice[choice.length - 1] == '*') {
                      inlineChoice.text = choice.substring(0, choice.length - 1);
                      inlineChoice.correct = true;
                    }
                    return inlineChoice;
                  });
                }

                result.answers.push({choices: inlineChoices, position: startIndex + count - 1});

                // increase location count
                result.locations = result.locations + 1;
              }

              // Toggle the current mode
              mode = mode === QUESTION_PARSE_MODE.QUESTION ? QUESTION_PARSE_MODE.ANSWER : QUESTION_PARSE_MODE.QUESTION;

              // Strip the characters off the front of the string
              source = source.substr(index + 3);

              // Find the next block
              index = source.indexOf("___");
            }

            // Push remaining text onto the question
            result.segments.push({text: $sce.trustAsHtml(source), position: startIndex + count});

            return result;
          }

          let unregisterOptionsWatch = $scope.$watch('options', onOptionsSet, true);

          function onOptionsSet() {
            if ($scope.options.context) {
              // if not yet initialized
              if (!$scope.initialized) {
                context = $scope.options.context;
                element = $scope.options.element;
                parseElement();
                $scope.isTeacher = context.userIsTeacher();

                loadAnswers();
                $scope.initialized = true;
              }
            }
          }

          $scope.$watch('savingElementId', function (newValue, oldValue) {
            // Submit the response selected in this element
            if (newValue && newValue.elementId === $scope.options.element.id) {
              $scope.didSubmit(newValue);
            }
          }, true);

          $rootScope.$on('teacher-notes', function () {
            if ($scope.isTeacher) {
              if (ActiveExperience.currentExperience().hideStudentResponses) {
                $scope.selectRespondent(context.userId);
              } else {
                $scope.selectRespondent(CorrectResponseId.CORRECTANSWERS);
              }
            }
          });

          function loadAnswers() {
            if (!element || !element.id) {
              return;
            }

            // Clear any existing responses as they will be reloaded
            $scope.respondents = [];
            $scope.allResponses = [];

            // if this is the teacher then add the set of correct answers for them
            if ($scope.isTeacher && (!$scope.options.context.getViewingInactiveExperience() ||
              ($scope.options.context.filteredUser && $scope.options.context.filteredUser.report === 1))) {
              // Add the correct responses to the teacher into the response set
              $scope.respondents.push({id: CorrectResponseId.CORRECTANSWERS, type: RespondentType.USER, showCheckMark: true});
              $scope.allResponses.push({
                respondentId: CorrectResponseId.CORRECTANSWERS,
                type: RespondentType.USER,
                response: $scope.correctChoices,
                correct: 1
              });
            }

            let isInactive = $scope.options.context.getViewingInactiveExperience();

            ElementsRestService.getSharedState($scope.options.context.experienceId, element.id, context.groupName, isInactive,
              function (result) {
                let isCached = false;
                if ($scope.cached) {
                  let cachedValue = $scope.cached({elementId: $scope.options.element.id});
                  if (cachedValue) {
                    let allCorrect = checkResponses(cachedValue);
                    let currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;
                    $scope.allResponses.push({
                      respondentId: currentRespondentId,
                      type: RespondentType.USER,
                      response: cachedValue,
                      correct: allCorrect
                    });
                    $scope.isDirty = true;
                    isCached = true;
                  }
                }

                if (result && Array.isArray(result) && result.length && !isCached) {
                  $scope.filterAnswers(result).forEach(function (answer) {
                    updateAnswer(answer);
                  });
                }

                // If the current student does NOT have an values then they can go directly to editing mode
                if (!$scope.isTeacher) {
                  // Determine the current user/current group id
                  let currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

                  let found = false;
                  if (!isCached) {
                    $scope.allResponses.forEach(function (response) {
                      // if this response matches the current user then
                      if (response.respondentId == currentRespondentId) {
                        // Make sure at least one of the responses contains a value
                        found = response.response && response.response.length;
                      }
                    });
                  }

                  $scope.selectRespondent($scope.isUsingSmallGroups() ? context.groupId : context.userId);

                  if ($scope.isDirty) {
                    finishedAnswers();
                  }

                  if (isCached) {
                    $scope.status = [];
                  }

                  // If no record found then automatically start editing
                  $scope.editing = (!found || isCached || $scope.options.quiz) && !isInactive;
                } else if (ActiveExperience.currentExperience() && !ActiveExperience.currentExperience().hideStudentResponses) {
                  // Just select the teacher by default
                  $scope.selectRespondent($scope.getRespondent() ? $scope.getRespondent() : CorrectResponseId.CORRECTANSWERS);
                }

                let service = $scope.options.elementRealtimeService;
                let EVENTS = service.EVENTS;

                service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                $scope.$on('$destroy', function () {
                  service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                });

                // Notify the widget that were are done loading the data
                widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
              },
              function (error) {
                ElementsErrorService.error(error);
              });
          }

          function updateRespondents(answer) {
            let answerRespondentId = $scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id;
            let currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;
            let respondentType = $scope.isUsingSmallGroups() ? RespondentType.GROUP : RespondentType.USER;

            // If this user is submitting real data then make sure they are in the list of users
            let respondents = $scope.respondents;
            if (answer.user_data && answer.user_data.length > 0 && ($scope.isTeacher || answerRespondentId === currentRespondentId)) {
              // See if they are already in the list
              let inList = false;
              respondents.forEach(function (respondent) {
                if (respondent.id === answerRespondentId) {
                  inList = true;
                }
              });

              // add to list if necessary
              if (!inList) {
                respondents.push({'id': answerRespondentId, 'type': respondentType});
              }
            } else if (!answer.user_data || !answer.user_data.length) {
              // if this is the teacher receiving this message then
              if ($scope.isTeacher) {
                // Remove this student from the list of students
                $scope.respondents = $scope.respondents.filter(function (respondent) {
                  return respondent.id !== $scope.selectedRespondent;
                });
              } else {
                $scope.editing = true;  // The student should go directly into edit mode
              }
            }

            // Sort if this is groups
            if ($scope.isUsingSmallGroups()) {
              respondents.sort();
            }

            if ($scope.isUsingSmallGroups()) {
              $scope.portionResponded = (respondents.length) / context.clazz.smallGroups;
            } else if (context.clazz.students.length) {
              $scope.portionResponded = (respondents.length) / context.clazz.students.length;
            } else {
              $scope.portionResponded = 0;
            }
          }

          function updateAnswer(answer) {
            // Exception case for no responses from server
            if (!answer.user_data) {
              return;
            }

            let context = $scope.options.context;

            let respondentId = $scope.isUsingSmallGroups() ? answer.small_gid : answer.user_id;

            // parse this into a JSON object
            answer.user_data = JSONStringUtility.parse(answer.user_data);

            // See if this user has a response already
            let found = false;
            $scope.allResponses.forEach(function (response) {
              // if the respondent id matches then just update the responses
              if (response.respondentId === respondentId) {
                response.response = answer.user_data;
                response.correct = checkResponses(answer.user_data);
                found = true;
              }
            });

            // Insert this into the full set of responses
            if (!found) {
              // Check to see if this user correctly answered
              let allCorrect = checkResponses(answer.user_data);

              // Add this student into the response set
              $scope.allResponses.push({
                respondentId: respondentId,
                type: RespondentType.USER,
                response: answer.user_data,
                correct: allCorrect
              });

              // Keep track of the user who changed the value in this group
              if ($scope.isUsingSmallGroups()) {
                $scope.groupUser[answer.small_gid] = answer.user_id;
              }
            }

            // if this is the current user then set these responses into the current response object
            if ($scope.selectedRespondent == respondentId) {
              $scope.responses = answer.user_data;
              updateChoiceStates();
            }

            // Update the respondents based on this answer
            updateRespondents(answer);
            return undefined;
          }

          function checkResponses(responses) {
            let allCorrect = true;
            let someCorrect = false;

            // Iterate over the responses and see if each correctly matches the correct response
            for (let index = 0; index < $scope.answerCount; ++index) {
              // See if this matches the nth value in the source
              if ($scope.correctChoices[index] && responses[index] && $scope.correctChoices[index] != responses[index]) {
                allCorrect = false;
              } else if ($scope.correctChoices[index] && responses[index] && $scope.correctChoices[index] == responses[index]) {
                someCorrect = true;
              }
            }

            return allCorrect ? 1 : (someCorrect ? -1 : 0);
          }

          function mapCorrectToIcon(correct) {
            if (correct === -1) {
              return IconOverlays.MINUS;
            } else if (correct === 1) {
              return IconOverlays.CHECK;
            } else {
              return IconOverlays.CROSS;
            }
          }

          $scope.correctOverlay = function (respondentId) {
            if (!$scope.isTeacher || !$scope.isAssessed) {
              return undefined;
            }

            // Search through all the responses
            for (let index = 0; index < $scope.allResponses.length; ++index) {
              // if this the same group
              if (respondentId === $scope.allResponses[index].respondentId) {
                return mapCorrectToIcon($scope.allResponses[index].correct);
              }
            }

            return IconOverlays.CROSS;
          };

          function stateChangedNotificationHandler(e) {
            let message = e.detail;
            let state = message.record;
            if (state.element_id != $scope.options.element.id) {
              return;
            }

            $log.debug("Received inline choice state update: " + JSON.stringify(message));

            // Need to convert user and group id's to integers
            if (state.user_id) {
              state.user_id = parseInt(state.user_id, 10);
            }

            if (state.small_gid) {
              state.small_gid = parseInt(state.small_gid, 10);
            }

            $log.debug("updating all answers");
            $scope.$apply(updateAnswer(state));
          }

          function selectRespondentData(respondentId) {
            let found = false;

            // Select this respondents data into the active set
            $scope.allResponses.forEach(function (response) {
              if (response.respondentId == respondentId) {
                // We found responses for this user so reset the UI to reflect their values
                $scope.responses = response.response.slice(0);
                updateChoiceStates();
                found = true;
              }
            });

            // if the selected user does not have responses then clear the values
            if (!found) {
              $scope.responses = createBlankArray($scope.answerCount);
              if (respondentId) {
                updateChoiceStates();
              }
              $scope.status = [];
            }

            $scope.editing = false;
          }

          $scope.getResponse = function (index, includeDropdown) {
            let response;
            if ($scope.responses[index] && $scope.responses[index].length) {
              response = $scope.responses[index];
            } else {
              response = 'Choose';
            }
            if (includeDropdown) {
              response = response + '<span class="caret inline-choice-caret"></span>';
            }
            return response;
          };

          $scope.calculateDropdownPosition = function (choices) {
            if ($scope.editing) {
              return "-50px";
            }
          };

          $scope.onSelectInlineChoice = function (choice, index) {
            $scope.responses[index] = choice.text;
            $scope.responseChanged();
          };

          $scope.canEditForRespondent = function (respondentId) {
            if (!$scope.options.context) {
              return false;
            }

            if ($scope.options.context.isPreview) {
              return false;
            }

            return $scope.isTeacher || ($scope.allowEditing && !$scope.options.quiz);
          };

          $scope.hasResponses = function () {
            return $scope.respondents.length;
          };

          $scope.getUserGroup = function (userId) {
            return context && context.getUserGroup(userId);
          };

          $scope.selectRespondent = function (respondentId) {
            // Set the respondents data
            $scope.selectedRespondent = respondentId;

            // This populates the UI with the selected data
            selectRespondentData(respondentId);

            // tell UI this respondent has data
            respondentHasData(respondentId);
          };

          function respondentHasData(respondentId) {
            $scope.allowEditing = false;

            // if this is NOT a past experience
            if (!$scope.options.context.getViewingInactiveExperience()) {
              // if this the teacher then just make sure it is not there own data
              if ($scope.isTeacher) {
                $scope.allowEditing = respondentId != context.userId;
              } else {
                // Loop through data and see if this user has any data
                $scope.allResponses.forEach(function (response) {
                  // if this response belongs to this user or the group this user is in OR if this is a teacher viewing a students responses
                  if (response.respondentId === respondentId) {
                    $scope.allowEditing = response.response && response.response.length;
                  }
                });
              }
            }
          }

          function hasData(userId) {
            let result = false;
            $scope.allResponses.forEach(function (response) {
              if (response.respondentId == userId) {
                result = true;
              }
            });
            return result;
          }

          $scope.getEditMenuItemsForUser = function (userId) {
            let editUserId = context.getSelectedRespondentId($scope.isUsingSmallGroups(), userId);
            let editOption =
              {
                text: '<div class="xp-element-menu-edit">Edit</div>',
                click: 'editResponses(' + editUserId + ')'
              };

            let menuOptions;
            // if this is a teacher then add the delete option when NOT editing
            if ($scope.isTeacher && hasData(context.userId)) {
              menuOptions =
                [
                  editOption,
                  {
                    text: '<div class="xp-element-menu-delete">Delete</div>',
                    click: 'requestDelete(' + userId + ')'
                  }
                ];
            } else {
              menuOptions = [editOption];
            }

            if ($scope.editing) {
              menuOptions =
                [
                  {
                    text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
                    click: 'cancelEdit(' + userId + ')'
                  }
                ];
            }

            return menuOptions;
          };

          $scope.editResponses = function (userId) {
            // reset the assessment status flag to hide the alert if it is showing
            $scope.assessmentStatus = false;

            $scope.selectRespondent(userId);

            // Clear the status for when in student facing feedback
            $scope.status = [];

            // Editing mode
            $scope.editing = true;
          };

          $scope.cancelEdit = function (userId) {
            // Re-selects original data for this user
            selectRespondentData($scope.selectedRespondent);

            if ($scope.changed) {
              $scope.changed({elementId: $scope.options.element.id, selection: null});
            }

            // Not longer in editing mode
            $scope.editing = false;
          };

          $scope.onRetry = function () {
            $scope.editResponses($scope.selectedRespondent);
          };

          function displayStudentFacingFeedback() {
            // if this is a student they are not editing and they are viewing their own data
            if (($scope.options.context.getStudentFacingFeedback() || $scope.options.context.getViewingInactiveExperience()) &&
              !$scope.isTeacher && !$scope.editing && $scope.isAssessed && !$scope.options.quiz) {

              // Determine the current user/current group id
              let currentRespondentId = $scope.isUsingSmallGroups() ? $scope.getUserGroup(context.userId) : context.userId;

              // Make sure the student has data
              let studentResponses = $scope.allResponses.filter(function (response) {
                return response.respondentId == currentRespondentId;
              });

              // if any results were found return success
              return studentResponses && studentResponses.length;
            }
            return false;
          }

          function updateChoiceStates() {
            // if this is a teacher then check all the responses to see if they are correct
            if (($scope.isTeacher && !$scope.editing) || displayStudentFacingFeedback()) {
              // Clear the current status markers
              $scope.status = [];

              // Loop over the responses and mark each
              for (let index = 0; index < $scope.responses.length; ++index) {
                // See if the response is correct
                if (!$scope.correctChoices[index] || !$scope.correctChoices[index].length) {
                  $scope.status[index] = 0;
                } else if ((CorrectResponseId.CORRECTANSWERS === $scope.selectedRespondent && $scope.isTeacher) ||
                  ($scope.responses[index] && $scope.correctChoices[index] == $scope.responses[index])) {
                  $scope.status[index] = 1;
                } else {
                  $scope.status[index] = -1;
                }
              }
            }
          }

          $scope.responseChanged = function () {
            // User made a change so set dirty flag
            $scope.isDirty = true;

            if ($scope.selectionMade) {
              $scope.selectionMade({selected: true});
            }

            if ($scope.changed) {
              $scope.changed({elementId: $scope.options.element.id, selection: $scope.responses});
            }

            // See if the user finished answering all choices
            finishedAnswers();

            // Disable/enable choices appropriately based on answer state
            updateChoiceStates();
          };

          $scope.didSubmit = function (savingElement) {
            if (!$scope.selectedRespondent || !$scope.options || !context || !element) {
              return;
            }

            // Disable editing
            if (!$scope.options.quiz) {
              $scope.editing = false;
            }

            // Save the answers to the server
            saveAnswers(savingElement);

            // Adjust the flag to hide the submit button
            $scope.answered = false;
          };

          $scope.requestDelete = function (respondentId) {
            ModalService.show(
              {
                title: 'Delete student responses?',
                buttons:
                  [
                    {
                      title: 'Delete',
                      click: 'deleteAnswers(); $hide();'
                    },
                    {
                      title: 'Cancel',
                      click: '$hide()'
                    }
                  ],
                deleteAnswers: function () {
                  deleteStudentResponses();
                }
              }
            );
          };

          function deleteStudentResponses() {
            // Clear out the responses for this student
            $scope.responses = [];

            // Save these new values
            saveAnswers();
          }

          function saveAnswers(savingElement) {
            if ($scope.selectedRespondent == CorrectResponseId.CORRECTANSWERS) {
              $scope.selectedRespondent = context.userId;
            }
            // Determine the user information to save this data under
            let groupId = $scope.options.context.getPostingGroupId($scope.isUsingSmallGroups());
            let userId = context.userId;

            // if this is the teacher then we need to get the correct user ID based on the current selected response
            if ($scope.isTeacher) {
              userId = $scope.isUsingSmallGroups() ? $scope.groupUser[$scope.selectedRespondent] : $scope.selectedRespondent;
            }

            // post the responses to the server
            ElementsRestService.saveUserState($scope.options.context.experienceId, element.id, userId, groupId, $scope.responses,
              function () {
                let answer = {user_id: userId, small_gid: groupId, user_data: $scope.responses};
                // Calls back to quiz letting it know the score has been saved
                if ($scope.scoreSaved) {
                  $scope.scoreSaved({finished: (savingElement && savingElement.finished)});
                }
                if ($scope.changed) {
                  $scope.changed({elementId: $scope.options.element.id, selection: null});
                }
                updateAnswer(answer);
                respondentHasData($scope.selectedRespondent);
                $scope.isDirty = false;

                // Show status immediately after student submits
                if ($scope.options.context.getStudentFacingFeedback() && $scope.isAssessed && !$scope.options.quiz) {
                  $scope.assessmentStatus = checkResponses(answer.user_data);
                }
              },
              function (error) {
                ElementsErrorService.error(error);
              });
          }

          function finishedAnswers() {
            let answersProvided = true;

            $scope.responses.forEach(function (response) {
              // if the response is blank then not done
              if (!response || !response.length) {
                answersProvided = false;
              }
            });

            $scope.answered = answersProvided;
          }

          $scope.isUsingSmallGroups = function () {
            return SHARE_MODE.isUsingSmallGroups($scope.share);
          };

          $scope.wrapRespondent = function (respondent) {
            let wrappedRespondent = new $scope.Respondent(respondent.id);
            wrappedRespondent.getType = function () {
              return respondent.type;
            };

            wrappedRespondent.isSelected = function () {
              return respondent.id === $scope.selectedRespondent;
            };

            wrappedRespondent.isCheckMark = function () {
              return respondent.showCheckMark;
            };

            wrappedRespondent.select = function () {
              $scope.selectRespondent(respondent.id);
            };

            wrappedRespondent.getOverlay = function getOverlay() {
              if (!respondent.showCheckMark) {
                return $scope.correctOverlay(respondent.id);
              } else {
                return null;
              }
            };

            return wrappedRespondent;
          };

        }]);

  angular.module('xp-element-inline-choice').directive('xpInlineChoiceDropdown', ['$window', function ($window) {
    return {
      restrict: 'AE',
      scope: {
        choices: '=',
        position: '=',
        xpClick: '&'
      },
      templateUrl: "xpInlineChoiceDropdown.tpl",
      replace: true,
      link: link
    };

    function link(scope, element, attrs) {
      scope.curElement = element[0];
      scope.xPos = null;
      scope.yPos = null;
      scope.onSelect = function (choice) {
        if (scope.xpClick) {
          scope.xpClick({choice: choice, position: scope.position});
        }
      };

      function calculateTextWidth(text) {
        let f = '20px helvetica',
          o = $('<div></div>')
            .text(text)
            .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f})
            .appendTo($('body')),
          w = o.width();
        o.remove();
        return w;
      }

      function calculateDropdownPosition(choices) {
        let clientRect = scope.curElement.parentNode.getBoundingClientRect();
        if (clientRect.left > 0) {
          let mainElem = angular.element(document.querySelector('main'));
          let mainRect = mainElem[0].getBoundingClientRect();
          let maxChoiceWidth = 0;
          choices.forEach(function (choice) {
            let textWidth = calculateTextWidth(choice.text);
            if (textWidth > maxChoiceWidth) {
              maxChoiceWidth = textWidth;
            }
          });
          if (maxChoiceWidth > 1032 - (clientRect.left - mainRect.left)) {
            if (clientRect.left - mainRect.left > 700) {
              scope.xPos = "unset";
              scope.yPos = "0px";
            } else {
              scope.xPos = (1032 - (clientRect.left - mainRect.left)) - maxChoiceWidth - 100;
              scope.yPos = "unset";
            }
          } else {
            scope.xPos = "0px";
            scope.yPos = "unset";
          }
        }
      }

      scope.leftDropdownPosition = function () {
        if (scope.choices && (!scope.xPos || !scope.yPos)) {
          calculateDropdownPosition(scope.choices);
        }
        return scope.xPos;
      };

      scope.rightDropdownPosition = function () {
        if (scope.choices && (!scope.xPos || !scope.yPos)) {
          calculateDropdownPosition(scope.choices);
        }
        return scope.yPos;
      };

    }
  }]);
})();
