'use strict';

angular.module('client.services').factory('WhiteboardService', ['$resource',
  function ($resource) {

    var whiteboardProjectorResource = $resource('/main/v1' + '/experiences/:id/whiteboard/projector', {presentation_state: '@presentation_state'},
        {
          getState: {method: 'GET', params: {id: '@experienceId'}},
          saveState: {method: 'POST', params: {id: '@experienceId'}},
          deleteState: {method: 'DELETE', params: {id: '@experienceId'}}
        });

    function getWhiteboardState(experienceId) {
      return whiteboardProjectorResource.getState({id: experienceId}).$promise;
    }

    function saveWhiteboardProjectorState(experienceId, presentationState) {
      return whiteboardProjectorResource.saveState({id: experienceId}, {presentation_state: presentationState}).$promise;
    }

    function deleteWhiteboardProjectorState(experienceId) {
      return whiteboardProjectorResource.deleteState({id: experienceId}).$promise;
    }

    var whiteboardControllerResource = $resource('/main/v1' + '/experiences/:id/whiteboard/controller', {controller_state: '@controller_state'},
        {
          saveState: {method: 'POST', params: {id: '@experienceId'}},
          deleteState: {method: 'DELETE', params: {id: '@experienceId'}},
        });

    function saveWhiteboardControllerState(experienceId, controllerState) {
      return whiteboardControllerResource.saveState({id: experienceId}, {controller_state: controllerState}).$promise;
    }

    function deleteWhiteboardControllerState(experienceId) {
      return whiteboardControllerResource.deleteState({id: experienceId}).$promise;
    }

    var whiteboardOptionsResource = $resource('/main/v1' + '/experiences/:id/whiteboard/projector/options', {show_student_responses: '@show_student_responses', navigation_sync: '@navigation_sync'},
        {
          updateOptions: {method: 'POST', params: {id: '@experienceId'}}
        });

    function updateProjectorOptions(experienceId, state) {
      let options = {};
      if (state.hasOwnProperty("show_student_responses")) {
        options.show_student_responses = state.show_student_responses;
      }
      if (state.hasOwnProperty("navigation_sync")) {
        options.navigation_sync = state.navigation_sync;
      }
      whiteboardOptionsResource.updateOptions({id: experienceId}, options).$promise;
    }

    var whiteboardScrollResource = $resource('/main/v1' + '/experiences/:id/whiteboard/projector/scroll', {scroll: '@scroll'},
        {
          scrollTo: {method: 'POST', params: {id: '@experienceId'}}
        });

    function scrollProjector(experienceId, scrollPos) {
      whiteboardScrollResource.scrollTo({id: experienceId}, {scroll: scrollPos}).$promise;
    }

    return {
      getWhiteboardState: getWhiteboardState,
      saveWhiteboardProjectorState: saveWhiteboardProjectorState,
      deleteWhiteboardProjectorState: deleteWhiteboardProjectorState,
      saveWhiteboardControllerState: saveWhiteboardControllerState,
      deleteWhiteboardControllerState: deleteWhiteboardControllerState,
      updateProjectorOptions: updateProjectorOptions,
      scrollProjector: scrollProjector
  };
}]);